import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./approach.css"
import Footer from "../components/Footer"

const Approach = () => {
  useEffect(() => {
    window.addEventListener("scroll", positionPointer)
    positionPointer()
    return () => {
      window.removeEventListener("scroll", positionPointer)
    }
  }, [])

  const positionPointer = () => {
    var scrollY = window.scrollY || window.pageYOffset

    var preContent = document.getElementById("preContent")
    var preContentHeight =
      preContent && preContent.childNodes.length > 0
        ? preContent.scrollHeight
        : 0

    var postContent = document.getElementById("postContent")
    var postContentHeight =
      postContent && postContent.childNodes.length > 0
        ? postContent.scrollHeight
        : 0

    var maxScrollY =
      document.documentElement.scrollHeight -
      window.innerHeight -
      preContentHeight -
      postContentHeight
    var path = document.getElementById("path1")

    var pathLen = path.getTotalLength()

    scrollY -= preContentHeight
    scrollY = scrollY < 0 ? 0 : scrollY

    maxScrollY = maxScrollY < 0 ? 1 : maxScrollY

    var dist = pathLen - (pathLen * scrollY) / maxScrollY

    dist = dist < 5 ? 30 : dist

    var pos = path.getPointAtLength(dist)

    if (dist + 1 <= pathLen) {
      var posAhead = path.getPointAtLength(dist + 1)
      var angle = Math.atan2(pos.y - posAhead.y, pos.x - posAhead.x)
    } else {
      var posBehind = path.getPointAtLength(dist - 1)
      var angle = Math.atan2(pos.y - posBehind.y, pos.x - posBehind.x)
    }

    var pointer = document.getElementById("pointer")
    pointer.setAttribute(
      "transform",
      "translate(" + pos.x + "," + pos.y + ") rotate(" + rad2deg(angle) + ")"
    )

    var sideTitle1 = document.getElementById("side-title-1")
    var sideLine1 = document.getElementById("side-line-1")

    var sideTitle2 = document.getElementById("side-title-2")
    var sideLine2 = document.getElementById("side-line-2")
    if (3800 > dist && dist > 2404) {
      sideTitle1.setAttribute("dx", -pos.y + 200)
    }
    if (1504 > dist && dist > 0) {
      sideTitle2.setAttribute("dx", -pos.y + 1706.585)
    }
    var node1 = document.getElementById("node-1")
    var node1Text = document.getElementById("node-1-text")
    var header1 = document.getElementById("header-1")
    var paragraph1 = document.getElementById("paragraph-1")
    if (dist < 4016) {
      node1.setAttribute("style", "fill:#000000")
      node1Text.setAttribute("style", "fill:#ffffff")
      header1.setAttribute("style", "opacity:1")
      paragraph1.setAttribute("style", "opacity:1")
    } else {
      node1.setAttribute("style", "fill:#ffffff")
      node1Text.setAttribute("style", "fill:#000000")
      header1.setAttribute("style", "opacity:0")
      paragraph1.setAttribute("style", "opacity:0")
    }

    var node2 = document.getElementById("node-2")
    var node2Text = document.getElementById("node-2-text")
    var header2 = document.getElementById("header-2")
    var paragraph2 = document.getElementById("paragraph-2")
    if (dist < 3130) {
      node2.setAttribute("style", "fill:#000000")
      node2Text.setAttribute("style", "fill:#ffffff")
      header2.setAttribute("style", "opacity:1")
      paragraph2.setAttribute("style", "opacity:1")
    } else {
      node2.setAttribute("style", "fill:#ffffff")
      node2Text.setAttribute("style", "fill:#000000")
      header2.setAttribute("style", "opacity:0")
      paragraph2.setAttribute("style", "opacity:0")
    }

    var childNode1 = document.getElementById("child-node-1")
    var childNodeText1 = document.getElementById("child-node-1-text")
    if (dist < 3571) {
      childNode1.setAttribute("style", "opacity:1")
      childNodeText1.setAttribute("style", "opacity:1")
    } else {
      childNode1.setAttribute("style", "opacity:0")
      childNodeText1.setAttribute("style", "opacity:0")
    }

    var childNode2 = document.getElementById("child-node-2")
    var childNodeText2 = document.getElementById("child-node-2-text")
    if (dist < 2777) {
      childNode2.setAttribute("style", "opacity:1")
      childNodeText2.setAttribute("style", "opacity:1")
    } else {
      childNode2.setAttribute("style", "opacity:0")
      childNodeText2.setAttribute("style", "opacity:0")
    }

    var childNode3 = document.getElementById("child-node-3")
    var childNodeText3 = document.getElementById("child-node-3-text")

    if (dist < 2404) {
      childNode3.setAttribute("style", "opacity:1")
      childNodeText3.setAttribute("style", "opacity:1")
    } else {
      childNode3.setAttribute("style", "opacity:0")
      childNodeText3.setAttribute("style", "opacity:0")
    }

    var node3 = document.getElementById("node-3")
    var node3Text = document.getElementById("node-3-text")
    var header3 = document.getElementById("header-3")
    var paragraph3 = document.getElementById("paragraph-3")
    if (dist < 2130) {
      node3.setAttribute("style", "fill:#000000")
      node3Text.setAttribute("style", "fill:#ffffff")
      header3.setAttribute("style", "opacity:1")
      paragraph3.setAttribute("style", "opacity:1")

      sideTitle1.setAttribute("style", "opacity:0.18")
      sideLine1.setAttribute("style", "opacity:0.18")

      sideTitle2.setAttribute("style", "opacity:1")
      sideLine2.setAttribute("style", "opacity:1")
    } else {
      node3.setAttribute("style", "fill:#ffffff")
      node3Text.setAttribute("style", "fill:#000000")
      header3.setAttribute("style", "opacity:0")
      paragraph3.setAttribute("style", "opacity:0")

      sideTitle1.setAttribute("style", "opacity:1")
      sideLine1.setAttribute("style", "opacity:1")

      sideTitle2.setAttribute("style", "opacity:0.18")
      sideLine2.setAttribute("style", "opacity:0.18")
    }

    var childNode4 = document.getElementById("child-node-4")
    var childNodeText4 = document.getElementById("child-node-4-text")
    if (dist < 1866) {
      childNode4.setAttribute("style", "opacity:1")
      childNodeText4.setAttribute("style", "opacity:1")
    } else {
      childNode4.setAttribute("style", "opacity:0")
      childNodeText4.setAttribute("style", "opacity:0")
    }

    var childNode5 = document.getElementById("child-node-5")
    var childNodeText5 = document.getElementById("child-node-5-text")
    var childNodeTitle5 = document.getElementById("child-node-5-title")
    var reversePath1 = document.getElementById("reverse-path-1")
    if (dist < 1668) {
      childNode5.setAttribute("style", "opacity:1")
      childNodeText5.setAttribute("style", "opacity:1")
      childNodeTitle5.setAttribute("style", "opacity:1")
      reversePath1.setAttribute("style", "opacity:1")
    } else {
      childNode5.setAttribute("style", "opacity:0")
      childNodeText5.setAttribute("style", "opacity:0")
      childNodeTitle5.setAttribute("style", "opacity:0")
    }

    var node4 = document.getElementById("node-4")
    var node4Text = document.getElementById("node-4-text")
    var header4 = document.getElementById("header-4")
    var paragraph4 = document.getElementById("paragraph-4")
    if (dist < 1153) {
      node4.setAttribute("style", "fill:#000000")
      node4Text.setAttribute("style", "fill:#ffffff")
      header4.setAttribute("style", "opacity:1")
      paragraph4.setAttribute("style", "opacity:1")
    } else {
      node4.setAttribute("style", "fill:#ffffff")
      node4Text.setAttribute("style", "fill:#000000")
      header4.setAttribute("style", "opacity:0")
      paragraph4.setAttribute("style", "opacity:0")
    }

    var childNode6 = document.getElementById("child-node-6")
    var childNodeText6 = document.getElementById("child-node-6-text")
    if (dist < 807) {
      childNode6.setAttribute("style", "opacity:1")
      childNodeText6.setAttribute("style", "opacity:1")
    } else {
      childNode6.setAttribute("style", "opacity:0")
      childNodeText6.setAttribute("style", "opacity:0")
    }

    var childNode7 = document.getElementById("child-node-7")
    var childNodeText7 = document.getElementById("child-node-7-text")
    if (dist < 458) {
      childNode7.setAttribute("style", "opacity:1")
      childNodeText7.setAttribute("style", "opacity:1")
    } else {
      childNode7.setAttribute("style", "opacity:0")
      childNodeText7.setAttribute("style", "opacity:0")
    }

    var childNode8 = document.getElementById("child-node-8")
    var childNodeText8 = document.getElementById("child-node-8-text")

    var childNode9 = document.getElementById("child-node-9")
    var childNodeText9 = document.getElementById("child-node-9-text")

    var reversePath2 = document.getElementById("reverse-path-2")
    if (dist < 105) {
      childNode8.setAttribute("style", "opacity:1")
      childNodeText8.setAttribute("style", "opacity:1")

      childNode9.setAttribute("style", "opacity:1")
      childNodeText9.setAttribute("style", "opacity:1")

      reversePath2.setAttribute("style", "opacity:1")
    } else {
      childNode8.setAttribute("style", "opacity:0")
      childNodeText8.setAttribute("style", "opacity:0")

      childNode9.setAttribute("style", "opacity:0")
      childNodeText9.setAttribute("style", "opacity:0")
    }
  }

  const rad2deg = rad => {
    return (180 * rad) / Math.PI
  }

  return (
    <Layout>
      <SEO title="Approach" />
      <h1 className={"hidden"}>Approach</h1>
      <div className="row mt-90 ">
        <div id="preContent">
          <p className="approachHeader">
            How we do business is as important as what we do.
          </p>
          <br />
          <p className="approachSubtext">
            Our approach to work is comprehensive and sets us apart from others.
          </p>
          <p className="approachSubtext" style={{ width: "90%" }}>
            Setting goals, developing strategy, designing future and
            transforming to digital. At every step of our business process,
            we're focussed on offer excellent solutions.{" "}
          </p>
          <div className="short-seperator-line hidden mt-40"> </div>
          <p className="approachCenteredSubtext">
            Our four stage approach is designed to drive conversation from ideas
            to action. We take you on a step-by-step journey to find your unique
            solution, then help you spread it far and wide.
          </p>
          {/* PRE-CONTENT CAN BE HERE */}
        </div>
        <div className="col-12">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            width="100%"
            viewBox="0 0 1700 2728.1"
            style={{ enableBackground: "new 0 0 1700 2728.1" }}
          >
            <path
              name="MAIN_PATH"
              id="path1"
              className="st0"
              d="M786,2674v-83h-4c-173.4,0-314-140.6-314-314s140.6-314,314-314c173.4,0,314-140.6,314-314s-140.6-314-314-314
	c-173.4,0-314-140.6-314-314s140.6-314,314-314h6c173.4,0,314-140.6,314-314S961.4,79,788,79"
            />

            <path
              id="pointer"
              d="
            M 0, 0
            m 0, 0
            a 21.4,21.4 0 1,0 42.8,0
            a 21.4,21.4 0 1,0 -42.8,0
        "
            />

            <g>
              <g id="reverse-path-2">
                <path className="st0" d="M792,1963c3.3,0,6.7,0.1,10,0.2" />
                <path
                  className="st1 dashAnimate"
                  d="M826.8,1964.9c157,17.3,279.2,150.4,279.2,312.1c0,165.9-128.6,301.7-291.6,313.2"
                />
                <path className="st0" d="M802,2590.8c-3.3,0.1-6.7,0.2-10,0.2" />
              </g>
            </g>
            <rect
              x="468"
              y="388.4"
              className="st2"
              width="495"
              height="192.5"
            />
            <text
              id="paragraph-1"
              transform="matrix(1 0 0 1 570.3662 408.2109)"
            >
              <tspan x="0" y="0" className="st3 st4">
                We learn everything about you,
              </tspan>
              <tspan x="-74.1" y="32.4" className="st3 st4">
                completely discover needs, set goals
              </tspan>
              <tspan x="-85.8" y="64.8" className="st3 st4">
                for the weeks to come, and determine
              </tspan>
              <tspan x="137.7" y="97.2" className="st3 st4">
                metrics for success.
              </tspan>
            </text>
            <circle
              id="node-1"
              className="st14"
              cx="785.5"
              cy="79.1"
              r="53.1"
            />
            <text
              id="header-1"
              transform="matrix(1 0 0 1 631.457 358.9521)"
              className="st5 st6"
            >
              we discover
            </text>
            <rect
              x="591.5"
              y="928"
              className="st2"
              width="504.5"
              height="407"
            />
            <text
              id="paragraph-2"
              transform="matrix(1 0 0 1 591.5137 947.8545)"
            >
              <tspan x="0" y="0" className="st3 st4">
                The insight gathered from the
              </tspan>
              <tspan x="0" y="32.4" className="st3 st4">
                discovery phase helps us to define the
              </tspan>
              <tspan x="0" y="64.8" className="st3 st4">
                challenge in a different way. Ideation is
              </tspan>
              <tspan x="0" y="97.2" className="st3 st4">
                the most important process and it
              </tspan>
              <tspan x="0" y="129.6" className="st3 st4">
                involves thinking about features,
              </tspan>
              <tspan x="0" y="162" className="st3 st4">
                interactions and target group. This
              </tspan>
              <tspan x="0" y="194.4" className="st3 st4">
                stage assesses the realism of what can
              </tspan>
              <tspan x="0" y="226.8" className="st3 st4">
                be done and analyzes how this project
              </tspan>
              <tspan x="0" y="259.2" className="st3 st4">
                corresponds with the corporate brand.
              </tspan>
            </text>
            <text
              id="header-2"
              transform="matrix(1 0 0 1 584.0142 901.728)"
              className="st5 st6"
            >
              we define
            </text>
            <rect x="449" y="1556" className="st2" width="526.5" height="352" />
            <text
              id="paragraph-3"
              transform="matrix(1 0 0 1 474.2339 1575.8545)"
            >
              <tspan x="0" y="0" className="st3 st4">
                The development stage involves a lot of
              </tspan>
              <tspan x="50.4" y="32.4" className="st3 st4">
                multi-disciplinary work – putting the
              </tspan>
              <tspan x="-20.7" y="64.8" className="st3 st4">
                designers together with internal partners,
              </tspan>
              <tspan x="9.9" y="97.2" className="st3 st4">
                such as engineers, developers or other
              </tspan>
              <tspan x="-11" y="129.6" className="st3 st4">
                departments that have expertise needed
              </tspan>
              <tspan x="-13.6" y="162" className="st3 st4">
                in the project. The important thing is that
              </tspan>
              <tspan x="22.4" y="194.4" className="st3 st4">
                all ideas aim for the same outcome: to
              </tspan>
              <tspan x="56.7" y="226.8" className="st3 st4">
                prototype, to validate and bring the
              </tspan>
              <tspan x="264.7" y="259.2" className="st3 st4">
                solution to fruition.
              </tspan>
            </text>
            <text
              id="header-3"
              transform="matrix(1 0 0 1 670.1782 1529.728)"
              className="st5 st6"
            >
              we develop
            </text>
            <rect
              x="572.5"
              y="2198"
              className="st2"
              width="465.5"
              height="339"
            />
            <text
              id="paragraph-4"
              transform="matrix(1 0 0 1 572.5137 2267.8545)"
            >
              <tspan x="0" y="0" className="st3 st4">
                We care about your feedback, it
              </tspan>
              <tspan x="0" y="32.4" className="st3 st4">
                gives us valuable insights which
              </tspan>
              <tspan x="0" y="64.8" className="st3 st4">
                allow us to continually improve and
              </tspan>
              <tspan x="0" y="97.2" className="st3 st4">
                serve you better.
              </tspan>
            </text>
            <text
              id="header-4"
              transform="matrix(1 0 0 1 573.0142 2221.728)"
              className="st5 st6"
            >
              we deliver
            </text>
            <g id="child-node-1-text">
              <text
                transform="matrix(1 0 0 1 1136.584 393.4858)"
                className="st5 st7"
              >
                emphatize
              </text>
            </g>
            <text
              id="side-title-1"
              transform="matrix(0 -1 1 0 1576.5586 351.6343)"
              className="st5 st8"
            >
              complexity
            </text>
            <text
              id="side-title-2"
              transform="matrix(0 -1 1 0 1576.5586 1606.585)"
              className="st5 st8"
            >
              simplicity
            </text>
            <circle id="child-node-2" cx="532.6" cy="834.1" r="21.4" />
            <circle id="child-node-1" cx="1101.6" cy="388.1" r="21.4" />
            <circle id="child-node-5" cx="1085.6" cy="1572.1" r="21.4" />
            <circle id="child-node-6" cx="531.6" cy="2083.1" r="21.4" />
            <circle id="child-node-7" cx="502.6" cy="2415.1" r="21.4" />
            <circle id="child-node-8" cx="785.6" cy="2591.1" r="21.4" />
            <circle
              id="child-node-9"
              className="st10"
              cx="1105.6"
              cy="2276.1"
              r="21.4"
            />
            <circle id="child-node-4" cx="980.6" cy="1405.1" r="21.4" />
            <circle id="child-node-3" cx="511.6" cy="1182.1" r="21.4" />
            <circle cx="786.6" cy="707.1" r="22.3" />
            <g>
              <text
                id="child-node-2-text"
                transform="matrix(1 0 0 1 331.3398 821.4858)"
              >
                <tspan x="0" y="0" className="st5 st7">
                  Insights &amp;
                </tspan>
                <tspan x="-53" y="33.6" className="st5 st7">
                  Opportunities
                </tspan>
              </text>
            </g>
            <g>
              <text
                id="child-node-3-text"
                transform="matrix(1 0 0 1 369.25 1203.4858)"
                className="st5 st7"
              >
                Ideate
              </text>
            </g>
            <g>
              <text
                id="child-node-4-text"
                transform="matrix(1 0 0 1 1009.625 1400.4858)"
                className="st5 st7"
              >
                Prototype
              </text>
            </g>
            <g>
              <text
                id="child-node-5-text"
                transform="matrix(1 0 0 1 1124.0156 1593.4858)"
                className="st5 st7"
              >
                Validate
              </text>
            </g>
            <g>
              <text
                id="child-node-9-text"
                transform="matrix(1 0 0 1 1141.3828 2287.4858)"
                className="st5 st7"
              >
                Learn
              </text>
            </g>
            <g>
              <text
                id="child-node-6-text"
                transform="matrix(1 0 0 1 409.5723 2083.4858)"
                className="st5 st7"
              >
                Build
              </text>
            </g>
            <g>
              <text
                id="child-node-7-text"
                transform="matrix(1 0 0 1 336.6016 2431.4858)"
                className="st5 st7"
              >
                Measure
              </text>
            </g>
            <g>
              <text
                id="child-node-8-text"
                transform="matrix(1 0 0 1 738.9531 2710.4858)"
                className="st5 st7"
              >
                Deploy
              </text>
            </g>
            <g>
              <text
                id="child-node-5-title"
                transform="matrix(1 0 0 1 1127.9453 1627.4858)"
              >
                <tspan x="0" y="0" className="st3 st11">
                  Always Validation,
                </tspan>
                <tspan x="-1.5" y="28.8" className="st3 st11">
                  Never Assumption
                </tspan>
              </text>
            </g>
            <line
              id="side-line-1"
              className="st0"
              x1="1507"
              y1="70"
              x2="1507"
              y2="1353"
            />
            <line
              id="side-line-2"
              className="st0"
              x1="1507"
              y1="1348"
              x2="1507"
              y2="2580"
            />
            <g>
              <path className="st2" d="M782,1686" />
            </g>
            <g>
              <g id="reverse-path-1">
                <path className="st0" d="M782,707c3.3,0,6.7,0,10,0.1" />
                <path
                  className="st13 dashAnimate"
                  d="M816.6,708.2c254.2,17.7,454.9,229.6,454.9,488.3c0,142.6-61,271-158.3,360.5"
                />
                <path className="st0" d="M1104,1565.2c-2.5,2.2-5,4.4-7.6,6.5" />
              </g>
            </g>
            <circle
              id="node-2"
              className="st14"
              cx="785.5"
              cy="706.1"
              r="53.1"
            />
            <circle
              id="node-3"
              className="st14"
              cx="786.5"
              cy="1335.1"
              r="53.1"
            />
            <text
              id="node-2-text"
              transform="matrix(1 0 0 1 772.7324 722.2358)"
              className="st5 st15"
            >
              2
            </text>
            <text
              id="node-3-text"
              transform="matrix(1 0 0 1 772.2002 1353.2358)"
              className="st5 st15"
            >
              3
            </text>
            <text
              id="node-1-text"
              transform="matrix(1 0 0 1 774.4629 96.2358)"
              className="st5 st15"
            >
              1
            </text>
            <circle
              id="node-4"
              className="st14"
              cx="785.5"
              cy="1963.1"
              r="53.1"
            />
            <text
              id="node-4-text"
              transform="matrix(1 0 0 1 771.7915 1979.2358)"
              className="st5 st15"
            >
              4
            </text>
          </svg>
        </div>
        <div id="postContent">
          <p className="approachBottomSubHeader">
            When the project ends, our relationship sure doesn’t. We’ll be
            <br />
            around for support, drinks, or to discuss your next big thing.
          </p>
          <br />
          <br />
          <br />
          <br />
          <p className="approachHeader">Steps are guides, not set in stone.</p>
          <br />
          <p className="approachSubtext" style={{ width: "85%" }}>
            Every client is different. Each has unique needs and a unique
            background, with a one-of-a-kind story. Depending on who you are and
            what you need, we may skip steps, add steps or even rearrange them.
          </p>

          <p className="approachCenteredHeader">
            discover how we can help your
            <br /> business to get in front the competition
          </p>
        </div>
        <Footer />
      </div>
    </Layout>
  )
}

export default Approach
